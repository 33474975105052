/* body {
  padding-top: 56px;
} */
.full-width-nav {

  padding: 0 15px !important;
  margin: 0;
  max-width: 100% !important;
  width: 100% !important;
}

#sidebar {
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  max-width: 240px;
}

#main-content {
  margin-left: 240px !important;
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  width: 100%;

}

/* Add padding to the top of the content to match the height of the navbar */
.padding-for-navbar {
  padding-top: 56px;
  /* Adjust to the height of your navigation bar */
}

@media screen and (max-width: 767px) {
  #sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }

  #main-content {
    margin-left: 0;
  }
}

.file-container {
  margin: 0px !important;
  max-height: 400px !important;
  overflow-y: scroll !important;
}

.App {
  display: flex;
  height: 100%;

}

.bg-g1 {
  background-color: #8EC5FC;
  background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);
}

.bg-g2 {
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* CSS */
.list-group-item {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}

.button-flex-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.icon-right {
  margin-left: auto;
}

.ReactModal__Overlay {
  z-index: 9999 !important;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.modal-title {
  color: #282c34;
}


.list-group-item {

  background: none !important;
  color: white !important;
  font-size: .9em;
  border: 0 !important;
  border-radius: 0 !important;

  * {
    border-radius: 0 !important;
  }
}

.list-group-item:first-child,
.list-group-item:last-child,
.list-group-item+.list-group-item.active {
  border: 0 !important;
  border-radius: 0 !important;

  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;

  * {
    border-radius: 0 !important;
  }

}

.list-group-item+.list-group-item.active,
.list-group-item.active {
  background: white !important;
  color: black !important;
  border-radius: none !important;
}

.file-table {

  max-height: 500px !important;
  overflow-y: scroll !important;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;

}


.btn-xs {
  padding: 1px 5px !important;
  font-size: 12px !important;
  line-height: 1.5 !important;
  border-radius: 3px !important;
}

.badge-tick {

  padding: 0 !important;
  background: green !important;

}

.dropzone-container {
  z-index: 777 !important
}

.dropzone:hover {
  border-color: #2196f3;
}

.collections-content {
  overflow-y: auto;
  max-height: calc(100vh - 150px);
  /* assuming dropzone height is 150px, adjust as needed */
  padding-bottom: 150px;
  /* space for the dropzone, adjust based on dropzone's height */
}

.dropzone-container {
  position: fixed;
  bottom: 0;
  width: calc(100% - 250px);
  /* Assuming sidebar is 250px, adjust as necessary */
  z-index: 1;
}

.no-collection-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
  /* Assuming your navbar is 60px high, adjust if different */
  font-size: 1.5em;
  /* You can adjust this as needed */
}


/* Full screen container */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  /* Semi-transparent white */
  z-index: 1000;
  /* Ensuring it's above other elements */
}

/* Loader animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #555;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

/* Container holding the loader and text */
.loader-content {
  display: flex;
  align-items: center;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}